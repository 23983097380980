import React from 'react';
import Main from '../containers/Layout';
import SupplierManagementContent from '../../content/pages/supplier-management.yml';
import SupplierManagementContainer from '@/containers/toolkit/SupplierManagementContainer';

export interface SupplierManagementPageContent {
  viewport1: {
    title1: string;
    title2: string;
    description: string;
    tickedDescription: { item: string }[];
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    title: string;
    carouselItems: {
      title: string;
      description: string;
    }[];
  };
  viewport3: {
    title: string;
    leftColumn: { description1: string }[];
    rightColumn: { description1: string }[];
    bottomText: string;
    button: { label: string; url: string };
  };
}

const SupplierManagement: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3 } =
    SupplierManagementContent as unknown as SupplierManagementPageContent;
  return (
    <Main>
      <SupplierManagementContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
        }}
      />
    </Main>
  );
};

export default SupplierManagement;
